// src/services/firebaseService.js
import { auth, db } from '../firebase-config';
import { onAuthStateChanged, createUserWithEmailAndPassword, signOut } from 'firebase/auth';
import {
  collection,
  getDocs,
  writeBatch,
  serverTimestamp,
  updateDoc,
  deleteDoc,
  doc,
  getDoc,
  addDoc,
  setDoc,
  query,
  where
} from 'firebase/firestore';

import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject
} from "firebase/storage";

import Papa from 'papaparse'; // CSV parsing library

// Function to monitor auth state changes
export const monitorAuthState = (onUserAuthenticated, onUserNotAuthenticated) => {
  return onAuthStateChanged(auth, user => {
    if (user) {
      onUserAuthenticated(user);
    } else {
      onUserNotAuthenticated();
    }
  });
};

// Function to save a new venue
export const saveVenue = async (venueData) => {
  const currentDate = new Date();
  const dateString = currentDate.toISOString().replace(/[-:T.]/g, '').slice(0, 14); // Format the date as YYYYMMDDHHmmss
  const documentID = `${venueData.venueid}_${dateString}`;

  await setDoc(doc(db, 'venueItems', documentID), venueData);
};

// Function to fetch venue items from Firestore based on the user's UID
export const fetchVenueItems = async (uid) => {
  try {
    const venueQuery = uid 
      ? query(collection(db, 'venueItems'), where('venueOwnerID', '==', uid))
      : collection(db, 'venueItems');
    const data = await getDocs(venueQuery);
    const itemsWithImages = await Promise.all(data.docs.map(async (doc) => {
      const venueData = doc.data();
      const imageRef = ref(getStorage(), venueData.imageURL);
      const imageDownloadUrl = await getDownloadURL(imageRef).catch(() => "");
      return { ...venueData, id: doc.id, imageDownloadUrl };
    }));
    return itemsWithImages;
  } catch (error) {
    console.error("Error fetching venue items:", error);
    return [];
  }
};

// Function to save parsed menu items
export const saveParsedMenuItems = async (parsedData) => {
  console.log("saveParsedMenuItems function called with data:", parsedData);

  if (!db) {
    console.error("Firestore database instance is not initialized");
    throw new Error("Database not initialized");
  }

  if (!Array.isArray(parsedData) || parsedData.length === 0) {
    throw new Error("Invalid or empty data provided");
  }

  const savedItems = [];
  const errors = [];

  for (const item of parsedData) {
    try {
      // Check for required fields using updated names
      if (!item['Name'] || !item['Price'] || !item['Menu Type'] || !item['Venue ID']) {
        console.warn(`Skipping invalid item:`, item);
        continue;
      }

      // Parse the Price field
      const price = parseFloat(item['Price'].toString().replace('£', '').trim());
      if (isNaN(price)) {
        console.warn(`Invalid price for item:`, item);
        continue;
      }

      // Prepare the item for Firestore
      const docRef = await addDoc(collection(db, 'parsedMenuItems'), {
        name: item['Name'].trim(),
        price: price,
        menuType: item['Menu Type'].trim(),
        venueid: item['Venue ID'].trim(),
        date: item['Date'],  // Optionally include Date
        createdAt: serverTimestamp()
      });

      savedItems.push(docRef.id);
      console.log("Document written with ID: ", docRef.id);

    } catch (error) {
      console.error("Error adding document: ", error);
      errors.push({ item, error: error.message });
    }
  }

  console.log(`Saved ${savedItems.length} items. Errors encountered: ${errors.length}`);

  if (errors.length > 0) {
    console.error("Errors:", errors);
  }

  return { savedItems, errors };
};


// Function to download menu items as CSV
export const downloadMenuItems = async () => {
  console.log("downloadMenuItems function called");

  const snapshot = await getDocs(collection(db, 'menuItems'));
  const menuItems = snapshot.docs.map(doc => doc.data());

  const csvData = menuItems.map(item => ({
    venueid: item.venueid,
    menuType: item.menuType,
    extractedText: item.extractedText,
    timestamp: new Date().toISOString()
  }));

  const csv = Papa.unparse(csvData);

  return csv;
};

// Function to fetch all menu items
export const fetchMenuItems = async () => {
  try {
    const response = collection(db, 'menuItems');
    const data = await getDocs(response);
    const menuItemsWithImages = await Promise.all(data.docs.map(async (doc) => {
      const menuItemData = doc.data();
      const imageRef = ref(getStorage(), menuItemData.imageURL);
      const imageDownloadUrl = await getDownloadURL(imageRef).catch(() => "default-image.jpg"); // Provide a default image if no URL is found
      return { ...menuItemData, id: doc.id, imageDownloadUrl };
    }));
    console.log('Fetched Menu Items:', menuItemsWithImages); // Log the fetched menu items
    return menuItemsWithImages;
  } catch (error) {
    console.error("Error fetching menu items:", error);
    return [];
  }
};

// Function to fetch users from Firestore
export const fetchUsers = async () => {
  try {
    const usersCollectionRef = collection(db, 'users');
    const data = await getDocs(usersCollectionRef);
    const users = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    return users;
  } catch (error) {
    console.error("Error fetching users:", error);
    return [];
  }
};

// Function to update user data in Firebase
export const updateUser = async (userId, updatedUserData) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, updatedUserData);
    console.log("User updated successfully");
  } catch (error) {
    throw new Error("Error updating user: " + error.message);
  }
};

// Function to register a new user
export const registerUser = async (email, password, firstName, lastName) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    if (user) {
      // Add first and last name to Firestore
      await setDoc(doc(db, 'users', user.uid), {
        firstName: firstName,
        lastName: lastName
      });
      return { status: 'success', message: 'User registered successfully' };
    }
  } catch (error) {
    // Handle errors here, such as email already in use or weak password
    return { status: 'error', message: error.message };
  }
};

// Function to update venue details
export const updateVenuePromo = async (venueId, updateData) => {
  try {
    const venueRef = doc(db, 'venueItems', venueId);
    await updateDoc(venueRef, updateData);
    console.log("Venue details updated successfully");
  } catch (error) {
    throw new Error("Error updating venue details: " + error.message);
  }
};

// Function to fetch menu items by venueId
export const fetchMenuItemsByVenue = async (venueId) => {
  try {
    const menuQuery = query(collection(db, 'menuItems'), where('venueid', '==', venueId));
    const data = await getDocs(menuQuery);
    const itemsWithImages = await Promise.all(data.docs.map(async (doc) => {
      const menuItemData = doc.data();
      const imageRef = ref(getStorage(), menuItemData.imageURL);
      const imageDownloadUrl = await getDownloadURL(imageRef).catch(() => "");
      return { ...menuItemData, id: doc.id, imageDownloadUrl };
    }));
    return itemsWithImages;
  } catch (error) {
    console.error(`Error fetching menu items for venueId ${venueId}:`, error);
    return []; // Return empty array on error
  }
};

// Function to update menu summary
export const updateMenuSummary = async (menuId, newSummary) => {
  try {
    const menuRef = doc(db, 'menuItems', menuId);
    await updateDoc(menuRef, { output: newSummary });
    console.log("Menu summary updated successfully");
  } catch (error) {
    throw new Error("Error updating menu summary: " + error.message);
  }
};

// Function to delete a menu item
export const deleteMenuItem = async (menuId, imageURL) => {
  const menuRef = doc(db, 'menuItems', menuId);
  const storage = getStorage();
  
  // Convert gs:// URL to path
  const path = imageURL.replace(`gs://${storage.app.options.storageBucket}/`, '');
  const imageRef = ref(storage, path);

  try {
    await deleteDoc(menuRef);
    await deleteObject(imageRef);
    console.log("Menu item and image deleted successfully");
  } catch (error) {
    throw new Error("Error deleting menu item: " + error.message);
  }
};

// Function to upload image and save menu
export const uploadImageAndSaveMenu = async ({ image, venueId, venueName, description, menuType }) => {
  try {
    const storage = getStorage();
    const dateString = new Date().toISOString().replace(/[^0-9]/g, "");
    const imageName = `${venueId}_${dateString}.jpg`;
    const storagePath = `menuItems/${imageName}`;
    const storageRef = ref(storage, storagePath);

    const uploadResult = await uploadBytes(storageRef, image);
    const downloadUrl = await getDownloadURL(uploadResult.ref);

    const newItem = {
      id: imageName,
      title: venueName,
      description,
      venueid: venueId,
      imageURL: downloadUrl,
      active: true,
      menuType,
      uploadDate: serverTimestamp() // Add uploadDate using serverTimestamp
    };

    const menuRef = doc(db, 'menuItems', imageName);
    await setDoc(menuRef, newItem);

    console.log('Menu item and image uploaded successfully');
  } catch (error) {
    console.error("Error uploading image and saving menu:", error);
    throw error;
  }
};

// Function to update the menu output field in Firestore
export const updateMenuOutput = async (menuId, output) => {
  try {
    const menuRef = doc(db, 'menuItems', menuId);
    await updateDoc(menuRef, { output });
    console.log("Menu output updated successfully");
  } catch (error) {
    throw new Error("Error updating menu output: " + error.message);
  }
};

// Function to trigger AI generation (placeholder for actual implementation)
export const generateMenuOutput = async (menuId) => {
  try {
    // Placeholder: Replace with actual AI generation logic
    const generatedOutput = `Generated output from AI for menuId: ${menuId}`;

    // Update the Firestore document with the generated output
    await updateMenuOutput(menuId, generatedOutput);
    console.log("Menu output updated successfully");
  } catch (error) {
    console.error("Error generating menu output:", error);
  }
};

// Function to update menu type
export const updateMenuType = async (menuId, newType) => {
  try {
    const menuRef = doc(db, 'menuItems', menuId);
    await updateDoc(menuRef, { menuType: newType });
    console.log("Menu type updated successfully");
  } catch (error) {
    throw new Error("Error updating menu type: " + error.message);
  }
};

// Function to get current user's email
export const getCurrentUserEmail = () => {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, user => {
      if (user) {
        resolve(user.email);
      } else {
        reject('No user logged in');
      }
    });
  });
};

// Function to create a new user
export const createUser = async (fullName, email, password) => {
  try {
    // Create user in Firebase Authentication
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Save user data in "foodies" collection in Firestore
    const foodieRef = doc(db, 'foodies', user.uid);
    await setDoc(foodieRef, {
      fullName: fullName,
      email: email,
      createdAt: serverTimestamp(),
    });

    console.log('User created and added to foodies collection');
  } catch (error) {
    console.error('Error creating user:', error);
    throw error; // Re-throw the error to handle it in the component
  }
};

// This function creates a new foodie document in Firestore without authentication
export const createFoodie = async ({ fullName, email }) => {
  // Generate a unique doc ID. You could also use something like `doc(db, 'foodies').id`
  // and then setDoc, or use addDoc to let Firestore generate one for you.
  const foodieRef = doc(db, 'foodies', crypto.randomUUID());
  
  await setDoc(foodieRef, {
    fullName: fullName,
    email: email,
    createdAt: serverTimestamp(),
  });
  
  console.log('Foodie created successfully in Firestore');
};

// Function to fetch foodie data
export const fetchFoodieData = async (uid) => {
  try {
    const foodieDocRef = doc(db, 'foodies', uid);
    const foodieDoc = await getDoc(foodieDocRef);
    if (foodieDoc.exists()) {
      return foodieDoc.data();
    } else {
      console.log('No such foodie document!');
      return null;
    }
  } catch (error) {
    console.error('Error fetching foodie data:', error);
    return null;
  }
};

export { signOut };

// Function to fetch all parsed menu items
export const fetchAllParsedMenuItems = async () => {
  try {
    const menuItemsSnapshot = await getDocs(collection(db, 'parsedMenuItems'));
    const menuItems = menuItemsSnapshot.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });
    return menuItems;
  } catch (error) {
    console.error('Error fetching all parsed menu items:', error);
    throw error;
  }
};