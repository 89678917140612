// src/pages/SignUp.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createFoodie } from '../services/firebaseService';
import './SignUp.css';

function SignUp() {
  const [fullName, setFullName] = useState('');
  const [email, setEmail]    = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      // Create a new foodie document directly in Firestore
      await createFoodie({ fullName, email });
      navigate('/');
    } catch (err) {
      console.error('Error creating foodie:', err);
      setError('Unable to create foodie record. Please try again.');
    }
  };

  return (
    <div className="container">
      <div className="formContainer">
        <img src={`${process.env.PUBLIC_URL}/spenu.png`} alt="Logo" className="logo" />
        
        <h2>Create Your Profile</h2>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            className="input"
            placeholder="Full Name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            required
          />
          <input
            type="email"
            className="input"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit" className="button">
            Create Account
          </button>
        </form>
      </div>
    </div>
  );
}

export default SignUp;