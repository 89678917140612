// ViewAllVenues.jsx
import React, { useState, useEffect } from 'react';
import { fetchVenueItems, fetchMenuItems } from '../services/firebaseService';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import VenueCardSkeleton from '../components/VenueCardSkeleton'; // Import the skeleton
import './ViewAllVenues.css';

const ViewAllVenues = ({ searchQuery, priceFilter }) => {
  const [venues, setVenues] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [filteredVenues, setFilteredVenues] = useState([]);
  const [priceFilters, setPriceFilters] = useState([]);
  const [regionFilters, setRegionFilters] = useState([]);
  const [cuisineFilters, setCuisineFilters] = useState([]);
  const [allRegions, setAllRegions] = useState([]);
  const [allCuisines, setAllCuisines] = useState([]);

  // **Loading States**
  const [isLoadingVenues, setIsLoadingVenues] = useState(true);
  const [isLoadingMenuItems, setIsLoadingMenuItems] = useState(true);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyATfjXiFc5FHpHQu63uWGzSEPKcAQ9Ntus',
  });

  useEffect(() => {
    const loadItems = async () => {
      try {
        setIsLoadingVenues(true);
        setIsLoadingMenuItems(true);

        const venuesData = await fetchVenueItems();
        const menusData = await fetchMenuItems();

        setVenues(venuesData);
        setMenuItems(menusData);

        // Extract unique regions and cuisines
        const regions = [...new Set(venuesData.map((venue) => extractCity(venue.address)))].sort();
        setAllRegions(regions);

        const cuisines = [
          ...new Set(venuesData.flatMap((venue) => venue.cuisine.split(',').map((c) => c.trim()))),
        ].sort();
        setAllCuisines(cuisines);

        // Initially, set all venues as filtered
        setFilteredVenues(venuesData);
      } catch (error) {
        console.error('Error fetching data:', error);
        // Optionally, set error states here to display error messages to users
      } finally {
        setIsLoadingVenues(false);
        setIsLoadingMenuItems(false);
      }
    };
    loadItems();
  }, []);

  useEffect(() => {
    let filtered = venues;

    if (searchQuery) {
      const searchTerms = searchQuery.toLowerCase().split(' ').filter(term => term.length > 0);

      // Filter menu items that include all search terms
      const filteredMenus = menuItems.filter(menu =>
        searchTerms.every(term => menu.extractedText.toLowerCase().includes(term))
      );

      // Get unique venue IDs from the filtered menus
      const venueIdsWithAllTerms = [...new Set(filteredMenus.map(menu => menu.venueid.trim()))];

      // Filter venues that have all search terms in their menu items
      filtered = filtered.filter(venue => venueIdsWithAllTerms.includes(venue.venueid.trim()));
    }

    // Apply additional filters
    if (priceFilter && priceFilter !== 'All') {
      filtered = filtered.filter((venue) => venue.price === priceFilter);
    }

    if (priceFilters.length > 0) {
      filtered = filtered.filter((venue) => priceFilters.includes(venue.price));
    }

    if (regionFilters.length > 0) {
      filtered = filtered.filter((venue) => regionFilters.includes(extractCity(venue.address)));
    }

    if (cuisineFilters.length > 0) {
      filtered = filtered.filter((venue) =>
        venue.cuisine.split(',').some((c) => cuisineFilters.includes(c.trim()))
      );
    }

    setFilteredVenues(filtered);
  }, [searchQuery, priceFilter, priceFilters, regionFilters, cuisineFilters, venues, menuItems]);

  const extractCity = (address) => {
    const parts = address.split(',');
    if (parts.length > 1) {
      const city = parts[1].trim();
      return city
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    }
    return '';
  };

  const handlePriceFilter = (price) => {
    setPriceFilters((prevFilters) =>
      prevFilters.includes(price) ? prevFilters.filter((p) => p !== price) : [...prevFilters, price]
    );
  };

  const handleRegionFilter = (region) => {
    setRegionFilters((prevFilters) =>
      prevFilters.includes(region) ? prevFilters.filter((r) => r !== region) : [...prevFilters, region]
    );
  };

  const handleCuisineFilter = (cuisine) => {
    setCuisineFilters((prevFilters) =>
      prevFilters.includes(cuisine) ? prevFilters.filter((c) => c !== cuisine) : [...prevFilters, cuisine]
    );
  };

  const handleCardClick = (id) => {
    window.open(`/venue/${id}`, '_blank');
  };

  return (
    <div className="view-all-venues">
      <h1>{isLoadingVenues ? 'Loading Restaurants...' : `${filteredVenues.length} Restaurants`}</h1>
      <div className="venues-content">
        <div className="filters-column">
          {/* Price Filters */}
          <div className="filter-section">
            <h3><i className="fas fa-tag icon"></i> Price</h3>
            <div className="price-buttons">
              {['£', '££', '£££', '££££', '£££££'].map((price) => (
                <button
                  key={price}
                  className={`price-button ${priceFilters.includes(price) ? 'active' : ''}`}
                  onClick={() => handlePriceFilter(price)}
                  disabled={isLoadingVenues}
                >
                  {price}
                </button>
              ))}
            </div>
          </div>
          {/* Region Filters */}
          <div className="filter-section">
            <h3><i className="fas fa-map-marker-alt icon"></i> Regions</h3>
            <div className="region-checkboxes">
              {isLoadingVenues
                ? [...Array(5)].map((_, index) => (
                    <div key={index} className="skeleton-text short"></div>
                  ))
                : allRegions.map((region) => (
                    <label key={region} className="region-checkbox">
                      <input
                        type="checkbox"
                        checked={regionFilters.includes(region)}
                        onChange={() => handleRegionFilter(region)}
                        disabled={isLoadingVenues}
                      />
                      {region}
                    </label>
                  ))}
            </div>
          </div>
          {/* Cuisine Filters */}
          <div className="filter-section">
            <h3><i className="fas fa-utensils icon"></i> Cuisine</h3>
            <div className="cuisine-checkboxes">
              {isLoadingVenues
                ? [...Array(5)].map((_, index) => (
                    <div key={index} className="skeleton-text short"></div>
                  ))
                : allCuisines.map((cuisine) => (
                    <label key={cuisine} className="cuisine-checkbox">
                      <input
                        type="checkbox"
                        checked={cuisineFilters.includes(cuisine)}
                        onChange={() => handleCuisineFilter(cuisine)}
                        disabled={isLoadingVenues}
                      />
                      {cuisine}
                    </label>
                  ))}
            </div>
          </div>
        </div>
        <div className="venues-all-list">
          {isLoadingVenues ? (
            // Render multiple skeletons while loading
            [...Array(6)].map((_, index) => <VenueCardSkeleton key={index} />)
          ) : filteredVenues.length > 0 ? (
            filteredVenues.map((venue, index) => (
              <div
                key={venue.id}
                className="venue-all-card"
                onClick={() => handleCardClick(venue.id)}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') handleCardClick(venue.id);
                }}
              >
                <img
                  src={venue.imageDownloadUrl}
                  alt={venue.venuename}
                  className="venue-all-image"
                  loading="lazy"
                />
                <div className="venue-all-info">
                  <h2 className="venue-all-name">{`${index + 1}. ${venue.venuename}`}</h2>
                  <div className="venue-all-details">
                    <span className="venue-all-price">{venue.price}</span>
                    <span className="venue-all-cuisine">{venue.cuisine}</span>
                    <span className="venue-all-location">{extractCity(venue.address)}</span>
                  </div>
                  {venue.promo && <div className="venue-promo">{venue.promo}</div>}
                </div>
              </div>
            ))
          ) : (
            <p>No venues match your search criteria.</p>
          )}
        </div>
        <div className="venues-all-map">
          {!isLoaded
            ? <VenueCardSkeleton />
            : (
              <GoogleMap
                mapContainerStyle={{ width: '100%', height: '100%' }}
                center={{ lat: 51.50451, lng: -0.66470 }}
                zoom={10}
              >
                {filteredVenues.map((venue, index) => (
                  <Marker
                    key={venue.id}
                    position={{ lat: venue.latitude, lng: venue.longitude }}
                    label={`${index + 1}`}
                  />
                ))}
              </GoogleMap>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default ViewAllVenues;
